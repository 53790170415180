import { debounce } from 'lodash';
import { useCallback } from 'react';

export default function useDebounce(callback, delay) {
  const debouncedFn = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay]
  );

  return debouncedFn;
};
