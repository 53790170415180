import { create } from 'axios';

const ajax = create({
  timeout: 100000
});

export const postCSV = (file, lots, events) => {
  const formData = new FormData();

  formData.append('file', file, file.name);
  lots.forEach((lot) => formData.append('lotIds', lot));
  events.forEach((event) => formData.append('eventIds', event));

  return ajax.post(`${process.env.REACT_APP_CSV_URL}`, formData );
};
