import './CreateVIPS.scss';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import { Button, TextField, List, ListItemControl, Checkbox, DialogContainer } from 'react-md';
import { Footer, SubHeader } from '../../components';
import * as routes from '../../constants/routes';
import { getItem } from '../../utils';
import { LoadingSpinner } from '@parkhub/parkhub-ui';

const EVENTS_AND_VIPS_QUERY = loader('../../graphql/eventsAndVIPs.query.graphql');
const createVIPsMutation = loader('../../graphql/createVip.mutation.graphql');

const CreateVip = ({ updateToast }) => {
  const history = useHistory();
  const [duplicates, setDuplicates] = useState([]);
  const [currentDuplicates, setCurrentDuplicates] = useState(false);
  const [vipList, setVipsList] = useState([{ first: '', last: '' }]);
  const [visible, setVisible] = useState(false);
  const [selectedLots, setSelectedLots] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);
  const initialInput = useRef(null);

  const { loading, data: eventsData } = useQuery(EVENTS_AND_VIPS_QUERY, {
    variables: {
      eventIds: getItem('selectedEvents') ? getItem('selectedEvents')[0] : [],
      sort: 'asc'
    }
  });

  const [createVIPs, { loading: updateLoading }] = useMutation(createVIPsMutation, {
    onCompleted(data) {
      if (data && data.createVIPs.uniqueRecordCount) {
        const count = data.createVIPs.uniqueRecordCount;

        updateToast([{
          text: `${count} ${count === 1 ? 'VIP' : 'VIPs'} created`,
          action: 'dismiss'
        }]);
        setTimeout(() => history.push(routes.VIPS, { refetch: true }), 100);
      }
    },
    onError(...error) {
      console.log(error);
      const text = error[0].message.includes('Duplicate') ?
        error[0].message :
        'Error creating VIP. If the problem persists please contact administrator.';

      updateToast([{
        action: 'dismiss',
        text
      }]);
    }
  });

  useEffect(() => {
    if (inputFocus) {
      setInputFocus(initialInput);
      setFocus(initialInput);
    }

    if (!getItem('selectedEvents') || !getItem('selectedEvents').length || getItem('selectedEvents').length > 1) {
      history.push(routes.ROOT);
    }
  }, [inputFocus, history]);

  useEffect(() => {
    let vipDuplicateCheck = vipList.filter((value, index, self) => {
      return index === self.findIndex((t) => {
        return t.first === value.first && t.last === value.last;
      });
    });

    return vipList.length === vipDuplicateCheck.length ? setCurrentDuplicates(false) : setCurrentDuplicates(true);
  }, [vipList]);

  function setFocus(ref) {
    ref.current.focus();
  }

  function addNameField() {
    setInputFocus(true);
    setVipsList([...vipList, { first: '', last: '' }]);
  };

  function removeNameField(index) {
    setVipsList(vipList.filter((vip, idx) => idx !== index));
  };

  function handleChange(val, index, name) {
    vipList[index][name] = val;
    setVipsList([...vipList]);
  }

  function handleSelection(id) {
    if (selectedLots.includes(id)) {
      setSelectedLots((prevSelectedLots) => {
        const newList = [...prevSelectedLots];

        return newList.filter((item) => item !== id);
      });

    } else {
      setSelectedLots((prevSelectedLots) => {
        return [id, ...prevSelectedLots];
      });
    }
  }

  function checkNames() {
    return vipList.filter(vip => {
      return (vip.first.length === 0 || vip.first.length > 50) || (vip.last.length === 0 || vip.last.length > 50);
    }).length === 0;
  }

  function handleCreation() {
    const variables = {
      eventIds: getItem('selectedEvents'),
      lotIds: selectedLots,
      vips: vipList.map(vip => {
        return { firstName: vip.first, lastName: vip.last };
      })
    };

    createVIPs({
      variables
    });
  }

  // Compares the old vips against the new vips to be created.
  function duplicateCheck() {
    const oldDuplicates = findOldDuplicates(vipList);
    if (oldDuplicates.length) {
      setDuplicates(oldDuplicates);
      setVisible(true);
    } else {
      handleCreation();
    }
  }

  function findOldDuplicates(list) {
    let newDuplicates = [];
    const currentNames = list.map((newVip => `${newVip.first} ${newVip.last}`));
    const compareList = eventsData.event[0].vips.map(item => item.fullName);

    compareList.forEach((name) => {
      currentNames.forEach((currentName) => {
        if (name.toLowerCase() === currentName.toLowerCase()) {
          newDuplicates.push(name);
        }
      });
    });

    return newDuplicates;
  };

  // check for duplicates in currently shown inputs and disable or allow create new vips button accordingly
  function checkForDuplicates(vip) {
    const duplicate = vipList.filter(({ first, last }) => vip.first.toLowerCase() === first.toLowerCase() && vip.last.toLowerCase() === last.toLowerCase());

    return duplicate.length > 1 ? true : false;
  }

  function hide() {
    setVisible(false);
  };

  function handleNavigation() {
    history.push(routes.VIPS);
  }

  function isDisabled() {
    return !(checkNames() && !currentDuplicates && Boolean(selectedLots && selectedLots.length));
  }

  const allLotsSelected = selectedLots.length === eventsData?.event[0].lots.length;
  
  function handleSelectAll() {
    if (allLotsSelected) {
      return setSelectedLots([]);
    }

    const lots = eventsData?.event[0].lots.map(lot => lot.id);
    return setSelectedLots(lots);
  }

  const createNewFields = (index, vip) => {
    const isDuplicate = checkForDuplicates(vip);

    return (
      <div key={index} className="name-fields row">
        <TextField
          autoComplete="off"
          ref={initialInput}
          id="first-name"
          name="first-name"
          label="First"
          className="first col-xs-5 col-md-6"
          maxLength={50}
          error={isDuplicate}
          errorText={isDuplicate ? 'Vip name cannot be duplicate' : 'Input cannot exceed 50 characters'}
          onChange={(e) => handleChange(e, index, 'first')}
        />
        <TextField
          autoComplete="off"
          className="last col-xs-5 col-md-6"
          id="last-name"
          name="last-name"
          label="Last"
          error={isDuplicate}
          onChange={(e) => handleChange(e, index, 'last')}
          maxLength={50}
          errorText={isDuplicate ? '' : 'Input cannot exceed 50 characters'}
          inlineIndicator={index !== 0 ? <Button icon onClick={() => removeNameField(index)}>delete</Button> : <div />}
        />
      </div>
    );
  };

  return (
    <>
      <div className="wrapper create-vips">
        <section className="row">
          <div className="col-xs">
            <SubHeader label="Create New VIPs" backAction={() => handleNavigation()} />
          </div>
        </section>
        <section className="row">
          <div className="names col-xs">
            <span className="title-name">Name</span>
            <div>
              {vipList.map((vip, index) => createNewFields(index, vip))}
            </div>
            <br />
            <div className="row end-xs">
              <div className="col-xs">
                <Button
                  className="button ghost"
                  disabled={currentDuplicates || !checkNames()}
                  onClick={!currentDuplicates && checkNames() ? addNameField : undefined}
                  flat
                >
                  Add another VIP
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className="row lots-title">
          <div className="col-xs">
            <span className="title-lots">Lots</span>
            <p className="subtitle-lots">Only lots for this event are shown</p>
          </div>
        </section>
        <section className="row">
          <div className="col-xs">
            <List className="list">
              <Checkbox
                inkDisabled
                checked={allLotsSelected}
                className="lot-select-all"
                id="select-all-control"
                label="Select All"
                name="list-control-checkbox"
                onChange={handleSelectAll}
              />
              {!loading && eventsData?.event[0]?.lots.map((lot, index) => {
                const isSelected = selectedLots && selectedLots.includes(lot.id);
                return (
                  <ListItemControl
                    key={index}
                    className={`${isSelected ? 'selected' : ''}`}
                    primaryAction={
                      <Checkbox
                        checked={_.includes(selectedLots, lot.id)}
                        id={`list-control-${index}`}
                        name={`list-control-${index}`}
                        label={lot.name}
                        onChange={() => handleSelection(lot.id)}
                      />
                    }
                  />
                );
              })
              }
            </List>
          </div>
        </section>
        <DialogContainer
          id="duplicates-modal"
          className="dialog"
          focusOnMount={false}
          visible={visible}
          stackedActions={false}
          title="Create duplicates?"
          onHide={hide}
          width="510px"
          actions={[
            <Button key="edit" swapTheming primary flat onClick={hide}>Edit List</Button>,
            <Button key="continue" swapTheming secondary onClick={handleCreation} flat>Continue</Button>
          ]}
          modal
        >
          <p>You already have the following entries in your VIP list:</p>
          <ul className="duplicates">
            {duplicates.map((duplicate, index) => {
              return (
                <li key={`${duplicate}-${index}`}>{`${duplicate}`}</li>
              );
            })}
          </ul>
        </DialogContainer>
        <Footer>
          <Button
            disabled={isDisabled()}
            flat
            onClick={() => duplicateCheck()}
            primary
            swapTheming
          >
            {vipList.length > 1 ? 'Create New Vips' : 'Create New Vip'}
          </Button>
        </Footer>
      </div>
      <LoadingSpinner show={loading || updateLoading} delay={0} />
    </>
  );
};

export default CreateVip;
