import './SubHeader.scss';
import React from 'react';
import { Button } from 'react-md';
import PropTypes from 'prop-types';

const SubHeader = ({ backAction, label, subText, children }) => {
  return (
    <div className="subheader">
      <div className="row">
        {backAction &&
          <div className="subheader-back-action-container">
            <Button className="back-action" onClick={backAction} icon>
              arrow_back
            </Button>
          </div>
        }
        <div className="col-xs">
          {children ?
            children :
            <>
              <h2 className="subheader-label">{label}</h2>
              <p className="subheader-sub-text">{subText}</p>
            </>
          }
        </div>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  backAction: PropTypes.func,
  children: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default SubHeader;
