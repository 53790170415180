import './EventVIPSTable.scss';
import React, { useState, useEffect } from 'react';
import QuestionMarkCircleIcon from '../../assets/Illustrations/QuestionMarkCircleIcon.svg';
import { Tooltipped, FontIcon } from 'react-md';
import { useLocation } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import ThItem from './ThItem';
import { resolvePropDepth } from '../../utils';
import _ from 'lodash';

const EventVIPSTable = ({ labelKeys = [], increaseCount, decreaseCount, data = [], duplicates, selected }) => {
  const [activeSortKey, setActiveSortKey] = useState('');
  const [sortedData, setSortedData] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const { pathname = '' } = useLocation();

  useEffect(() => {
    const formatString = str => str.trim().toLowerCase();
    const initialNameSort = [...data].sort((a, b) => {
      const name1 = a.lastName || '';
      const name2 = b.lastName || '';
      return formatString(name1) < formatString(name2) ? -1 : 1;
    });

    return pathname === '/vips' ? setSortedData(initialNameSort) : setSortedData(data);
  }, [data, pathname]);

  useEffect(() => {
    if (activeSortKey !== '') {
      setSortedData(
        _.orderBy(data, [x => x[activeSortKey] && x[activeSortKey].toString().toLowerCase()], sortDirection)
      );
    }
  }, [activeSortKey, data, sortDirection]);

  function handleClick(item) {
    if (!selected.includes(item.id)) {
      increaseCount(item.id, item.name);
    } else {
      decreaseCount(item.id, item.name);
    }
  }

  function sortData(key = 'fullName', sortable) {
    if (sortable) {
      if (key === activeSortKey) {
        return setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }

      setActiveSortKey(key);
      setSortDirection('asc');
    }
  }

  function trClass(id) {
    const dups = duplicates || [];
    const isDuplicate = dups.includes(id);
    const isSelected = selected.includes(id);

    const status = `${isDuplicate ?
      'duplicate' : ''}${isDuplicate && isSelected ? '-' : ''}${isSelected ? 'selected' : ''}${!isSelected && !isDuplicate ? 'default' : ''}`;

    return status;
  }

  function format(data, formatter) {
    return !!formatter ? formatter(data) : data;
  }

  return (
    <>
      <table className="item-list-table">
        <thead>
          <tr>
            <th />
            {labelKeys.map(lk => {
              const { key = '', label = '', sortable = true } = lk;
              const sortKey = key === 'fullName' ? 'lastName' : key;
              const thClass = `table-columns-${labelKeys.length}${sortable ? ' sortable' : ''}`;

              return (
                <th className={thClass} key={`th-${key}`}>
                  <ThItem
                    activeSortKey={activeSortKey}
                    sortKey={sortable && sortKey}
                    sortable={sortable}
                    onClick={sortData}
                    direction={sortDirection}
                  >
                    {label}
                  </ThItem>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedData.map(item => {
            const { id = '' } = item;

            return (
              <tr key={`tr-${id}`} className={trClass(id)} onClick={() => handleClick(item)}>
                <td>
                  <FontIcon>{selected.includes(id) ? 'check_box' : 'check_box_outline_blank'}</FontIcon>
                </td>
                {labelKeys.map(lk => {
                  const dataKey = `${id}-${lk.key}`;

                  if (lk.key === 'lots') {
                    const lotKey = item[lk.key];
                    const number = lotKey.length;
                    const lotsLabel = [...lotKey]
                      .sort((a, b) => (a.name < b.name ? -1 : 1)) // alphabetical sort
                      .map(lot => <li key={lot.id}>{lot.name}</li>);

                    return (
                      <td key={dataKey}>
                        <div className={lk.key}>
                          <span>
                            {number} lot{number !== 1 ? 's' : ''}
                          </span>
                          <span className="tooltip-container">
                            {lk.key === 'lots' && (
                              <Tooltipped
                                label={number > 0 ? <ul>{lotsLabel}</ul> : null}
                                tooltipClassName="lot-permissions-tooltip"
                              >
                                <div style={{ position: 'relative' }}>
                                  <img className="lot-tooltip" src={QuestionMarkCircleIcon} alt="tooltip" />
                                </div>
                              </Tooltipped>
                            )}
                          </span>
                        </div>
                      </td>
                    );
                  }

                  return (
                    <td key={dataKey} className={`td-${lk.key.toLowerCase()}`}>
                      <TextTruncate text={format(resolvePropDepth(lk.key, item).toString(), lk.formatter)} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default EventVIPSTable;
