import './TopActionBar.scss';
import React from 'react';
import { Button } from 'react-md';
import { SearchInput } from '../index';
import { setItem } from '../../utils/sessionStorage';

const TopActionBar = ({ events, selected, setView, listView, update, children, setSearchTerm, searchTerm }) => {
  function selectAll() {
    setItem('selectedEvents', events.map(event => event.id));
    update();
  }

  function deselectAll() {
    setItem('selectedEvents', []);
    update();
  }

  return (
    <div className="top-action-bar row">
      <div className="col-xs-12 col-sm-6">
        <div className="search-filter">
          <SearchInput
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            placeholder="Search by keyword"
          />
          {children}
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="action-container">
          <Button
            className="list-view"
            flat
            iconChildren={listView ? 'view_module' : 'view_list'}
            id="card-view-toggle"
            onClick={setView}
          >
            {listView ? 'Card View' : 'List View'}
          </Button>

          {
            selected.length === events.length ?
              <Button className="button ghost" onClick={deselectAll} flat>Deselect All</Button> :
              <Button className="button ghost" onClick={selectAll} flat>Select All</Button>
          }
        </div>
      </div>
    </div>
  );
};

export default TopActionBar;
