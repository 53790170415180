import './Events.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment';
import Fuse from 'fuse.js';
import cx from 'classnames';
import { DialogContainer, Button } from 'react-md';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import {
  TopActionBar,
  EventCard,
  Footer,
  SubHeader,
  EventVIPSTable,
  FilterChip,
  FilterBar
} from '../../components';
import { LOTS, VIPS } from '../../constants/routes';
import {
  setItem,
  getItem,
  dateTimeFormatter,
  FUSE_OPTIONS,
  createFilterList,
  removeItem,
  isNotEmpty
} from '../../utils';
import NoEvents from '../../assets/Illustrations/NoRows.svg';

const storageKey = 'selectedEvents';
const eventsQuery = loader('../../graphql/events.query.graphql');
const eventsListLabelKeys = [
  { label: 'event name', key: 'name', sortable: false },
  { label: `VIPs assigned`, key: 'vipCount', sortable: false },
  { label: 'location name', key: 'landmark.name', sortable: false },
  { label: 'parking time', key: 'parkingFrom', sortable: false, formatter: dateTimeFormatter }
];

const Events = () => {
  const history = useHistory();
  const location = useLocation();
  const [listView, setListView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [locationTypeFilter, setLocationTypeFilter] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState('');
  const today = moment().startOf('day').format();

  const { loading, error, data = {}, refetch } = useQuery(eventsQuery, {
    variables: {
      after: today,
      live: true,
      locationIds: locationTypeFilter,
      sort: 'asc'
    }
  });

  useEffect(() => {
    if (location.state && location.state.refetch) {
      refetch();
    }
    removeItem(storageKey);
  }, [location, refetch]);

  function increaseCount(id) {
    let selectedArray = getItem(storageKey) || [];
    selectedArray = [...selectedArray, id];

    setItem(storageKey, selectedArray);
    forceUpdate();
  }

  function decreaseCount(id) {
    let selectedArray = getItem(storageKey) || [];
    selectedArray = selectedArray.filter(eventId => eventId !== id);

    setItem(storageKey, selectedArray);
    forceUpdate();
  }

  function setView() {
    setListView(prevListView => !prevListView);
  }

  function navigateToLots() {
    history.push(LOTS);
  }

  function hideDialog() {
    setVisibleDialog('');
  }

  function filterData(events) {
    const fuse = new Fuse(events, FUSE_OPTIONS);
    const results = fuse.search(searchTerm);

    return searchTerm === '' ? events : results.map(result => result.item);
  }

  function applyFilter(value, filterKey) {
    if (filterKey === 'locationTypeFilter') {
      setLocationTypeFilter(value);
    }
  }

  function handleWarningClick(selectedVipCount) {
    selectedVipCount ? setVisibleDialog('vips present') : navigateToLots();
  }

  function handleUploadClick(selectedLandmarks, selectedVipCount) {
    const multipleLandmarks = selectedLandmarks.length > 1;
    const shouldDialogDisplay = multipleLandmarks || selectedVipCount > 0;
    const dialogToShow = multipleLandmarks ? 'multiple landmarks' : 'vips present';

    return shouldDialogDisplay ? setVisibleDialog(dialogToShow) : navigateToLots();
  }

  const selectedArray = getItem(storageKey) || [];
  const selectedEvents = isNotEmpty(data)
    ? data.event.filter(event => selectedArray.includes(event.id))
    : [];

  const selectedLandmarks = selectedEvents.reduce(
    (accumulator, currentEvent) => {
      const { landmark: { name: landmarkName = '' } = {} } = currentEvent;

      return accumulator.includes(landmarkName)
        ? accumulator
        : [...accumulator, landmarkName];
    },
    []
  );

  const selectedVipCount = selectedEvents.reduce((total, currentEvent) => {
    const { vipCount = 0 } = currentEvent;

    return total + vipCount;
  }, 0);

  const singleEventSelected = getItem(storageKey) && getItem(storageKey).length === 1;
  const multipleEventsSelected = getItem(storageKey) && getItem(storageKey).length >= 1;

  return (
    <>
      <div className="event-tiles wrapper">
        <div className="row">
          <div className="col-xs">
            <SubHeader
              label="Events"
              subText="Select an event to view or edit the VIP list. Select multiple events to upload new VIPs."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs">
            {!loading && !error && data?.landmarks && (
              <TopActionBar
                events={filterData(data.event)}
                selected={selectedArray}
                update={forceUpdate}
                setView={setView}
                listView={listView}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              >
                <FilterBar>
                  {isNotEmpty(data.landmarks) && (
                    <FilterChip
                      data={createFilterList(data.landmarks, 'name', locationTypeFilter)}
                      onApply={applyFilter}
                      filterKey="locationTypeFilter"
                      className="locations-filter"
                      label="Locations"
                    />
                  )}
                </FilterBar>
              </TopActionBar>
            )}
          </div>
        </div>
        {!loading && !error && isNotEmpty(data.event) ? (
          <div className="row event-cards-container">
            {!listView &&
              !loading &&
              !error &&
              data.event &&
              filterData(
                data.event
              ).map(event => (
                <div key={event.id} className="col-xs-6 col-sm-4 col-md-3">
                  <EventCard
                    increaseCount={increaseCount}
                    decreaseCount={decreaseCount}
                    key={event.id}
                    event={event}
                    selected={selectedArray}
                  />
                </div>
              ))}
            {listView && !loading && !error && data.event && (
              <div className="col-xs">
                <EventVIPSTable
                  labelKeys={eventsListLabelKeys}
                  storageKey={storageKey}
                  increaseCount={increaseCount}
                  decreaseCount={decreaseCount}
                  selected={selectedArray}
                  data={filterData(
                    data.event
                  )}
                />
              </div>
            )}
          </div>
        ) : !loading && !error && !isNotEmpty(data.event) ? (
          <div className="row no-show">
            <img src={NoEvents} alt="tooltip" />
            <p>No events to show.</p>
          </div>
        ) : (
          !loading &&
          error && <h4 className="error-text">Uh Oh! Something went wrong!</h4>
        )}
      </div>
      <Footer>
        <>
          <Button
            flat
            primary
            swapTheming
            disabled={singleEventSelected ? false : true}
            className={cx('text', { blue: singleEventSelected })}
            onClick={() =>
              history.push(VIPS, {
                refetch: location.state && location.state.refetch
              })
            }
          >
            Edit Event
          </Button>
          <Button
            flat
            primary
            swapTheming
            disabled={
              multipleEventsSelected ? false : true
            }
            className={cx('text', { blue: multipleEventsSelected })}
            onClick={() => handleUploadClick(selectedLandmarks, selectedVipCount)}
          >
            Upload CSV
          </Button>
        </>
      </Footer>
      <DialogContainer
        id="csv-upload-dialog"
        className="dialog"
        visible={visibleDialog === 'vips present'}
        onHide={hideDialog}
        width="510px"
        actions={[
          <Button key="edit" swapTheming primary flat onClick={hideDialog}>
            Edit Selection
          </Button>,
          <Button
            key="continue"
            swapTheming
            secondary
            flat
            onClick={navigateToLots}
          >
            Continue CSV Upload
          </Button>
        ]}
        title="Just a reminder"
      >
        <p>When uploading CSVs, be aware that you might be creating duplicates that are already in your system.</p>
      </DialogContainer>
      <DialogContainer
        id="csv-upload-landmark-dialog"
        className="dialog"
        visible={visibleDialog === 'multiple landmarks'}
        onHide={hideDialog}
        titleClassName="dialog-title-with-warning"
        width="510px"
        actions={[
          <Button key="edit" swapTheming primary flat onClick={hideDialog}>
            Edit Selection
          </Button>,
          <Button
            key="continue"
            swapTheming
            secondary
            flat
            onClick={() => handleWarningClick(selectedVipCount)}
          >
            Continue CSV Upload
          </Button>
        ]}
        title="Multiple locations selected!"
      >
        <p>The events you&apos;ve selected have more than one location. Are you sure you&apos;d like to apply VIPs to multiple locations?</p>
      </DialogContainer>
      <LoadingSpinner show={loading} delay={0} />
    </>
  );
};

export default Events;
