import './SearchInput.scss';
import React, { useEffect, useState } from 'react';
import { SearchIcon } from '../../assets/react-icons/';
import { useDebounce } from '../../hooks';
export function SearchInput({ searchTerm, setSearchTerm, placeholder }) {
  const applyChange = useDebounce((nextValue) => handleChangeDebounced(nextValue, 'search'), 400);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearchTerm('');
  }, [setSearchTerm]);

  function handleChangeDebounced(val) {
    setSearchTerm(val);
  };

  function handleSearchChange(value) {
    setSearch(value);
    applyChange(value);
  }

  return (
    <div className="search-input-container" >
      <input type="text" onChange={(e) => handleSearchChange(e.target.value)} placeholder={placeholder} value={search} />
      <span>
        <SearchIcon color="rgba(0,0,0,.6)" width={24} />
      </span>
    </div>
  );
}
