import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { getItem, isNotEmpty } from '../../utils';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { SubHeader, Footer } from '../../components';
import { List, ListItemControl, Checkbox, Button } from 'react-md';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import NoLots from '../../assets/Illustrations/NoRows.svg';

let intersectedLots = [];

const lotsQuery = loader('../../graphql/lots.query.graphql');

const Lots = () => {
  const history = useHistory();
  const [selectedLots, setSelectedLots] = useState([]);
  const { loading, error, data } = useQuery(lotsQuery, { variables: { eventIds: getItem('selectedEvents') } });
  const selectedEvents = getItem('selectedEvents');

  useEffect(() => {
    if (!isNotEmpty(selectedEvents)) {
      history.push(routes.ROOT);
    }
  }, [history, selectedEvents]);

  function handleChange(v, id) {
    if (v) {
      setSelectedLots((prevSelectedLots) => {
        return [id, ...prevSelectedLots];
      });

    } else {
      setSelectedLots((prevSelectedLots) => {
        const newList = [...prevSelectedLots];

        return newList.filter((item) => item !== id);
      });
    }
  }

  function handleNavigate() {
    history.push(routes.CSV, { selectedLotsForCSV: selectedLots });
  }

  function isChecked(id) {
    return _.includes(selectedLots, id);
  }

  function isDisabled() {
    return selectedLots.length < 1;
  }

  function getIntersectedLotsFromEvents(events) {
    let lots = [];

    events.forEach((event, index) => {
      if (index === 0) {
        lots = [...event.lots];
      } else {
        lots = [..._.intersection(lots, event.lots)];
      }
    });

    intersectedLots = lots;

    return lots;
  }

  return (
    <>
      <div className="wrapper">
        <section className="row">
          <div className="col-xs">
            <SubHeader
              backAction={() => history.push(routes.ROOT)}
              label="Lot list"
              subText="The following lots or garages are available for your selected events. Select which lots or garages VIPs will have access to"
            />
          </div>
        </section>
        <section className="row lots-list">
          <div className="col-xs">
            {error && (
              <h4 className="error-text">Uh oh! Something went wrong!</h4>
            )}
            <List className="list">
              {!loading && !error && data?.lots &&
                  getIntersectedLotsFromEvents(data.lots) && intersectedLots.length ? intersectedLots.map((item, index) => {
                  return (
                    <ListItemControl
                      className={isChecked(item.id) ? 'selected' : ''}
                      key={index}
                      primaryAction={
                        <Checkbox
                          id={`list-control-${index}`}
                          name={`list-control-${index}`}
                          label={item.name}
                          onChange={(v) => handleChange(v, item.id)}
                          checked={isChecked(item.id)}
                        />
                      }
                    />
                  );
                })
                : !loading && !error &&
                  <div className="row no-show">
                    <img src={NoLots} alt="tooltip" />
                    <p>No shared lots or garages</p>
                  </div>
              }
            </List>
          </div>
        </section>
        <section>
          <Footer>
            <Button disabled={isDisabled()} flat swapTheming primary onClick={handleNavigate}>NEXT: CSV UPLOAD</Button>
          </Footer>
        </section>
      </div>
      <LoadingSpinner show={loading} delay={0} />
    </>
  );
};

export default Lots;
