import './EditVIP.scss';
import React, { useEffect, useState } from 'react';
import { Button, TextField, List, ListItemControl, Checkbox, DialogContainer } from 'react-md';
import { Footer, SubHeader } from './../../components/index';
import * as routes from '../../constants/routes';
import { getItem } from '../../utils/sessionStorage';
import { isNotEmpty } from '../../utils/index';
import { useQuery, useMutation } from '@apollo/client';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { loader } from 'graphql.macro';
import { useHistory, useLocation } from 'react-router-dom';

const EVENTS_AND_VIPS_QUERY = loader('../../graphql/eventsAndVIPs.query.graphql');
const updateVIPMutation = loader('../../graphql/updateVip.mutation.graphql');
const storageKeyEvents = 'selectedEvents';

const EditVIP = ({ updateToast }) => {
  const location = useLocation();
  const history = useHistory();
  const vip = location.state.vip;
  const [backModalVisible, setBackModalVisible] = useState(false);
  const [duplicatesModalVisible, setDuplicatesModalVisible] = useState(false);
  const [firstName, handleFirstNameChange] = useState('');
  const [lastName, handleLastNameChange] = useState('');
  const [selectedLots, setSelectedLots] = useState([]);
  const [duplicates, setDuplicates] = useState();
  const { error, loading, data: eventsData } = useQuery(EVENTS_AND_VIPS_QUERY, {
    variables: {
      eventIds: getItem(storageKeyEvents) || []
    }
  });
  
  const [updateVIP, { loading: updateLoading }] = useMutation(updateVIPMutation, {
    onCompleted(data) {
      if (data) {
        updateToast([{
          text: `VIP updated`,
          action: 'dismiss'
        }]);
        setTimeout(() => history.push(routes.VIPS, { refetch: true }), 100);
      }
    },
    onError(...error) {
      console.log(error);

      updateToast([{
        text: `Error updating VIP. If the problem persists please contact administrator`,
        action: 'dismiss'
      }]);
    }
  });

  // Init data
  useEffect(() => {
    if (vip) {
      setSelectedLots(vip.lots.map(lot => lot.id));
      handleFirstNameChange(vip.firstName);
      handleLastNameChange(vip.lastName);
    }
  }, [vip]);

  // redirects if someone went to this page too early
  useEffect(() => {
    if (!getItem(storageKeyEvents) || !getItem(storageKeyEvents).length || getItem(storageKeyEvents).length > 1) {
      history.push(routes.ROOT);
    } else if (!location.state && location.state.vip) {
      history.push(routes.VIPS);
    }
  }, [history, location]);

  function handleNameChange(val, name) {
    switch (name) {
      case 'firstName':
        handleFirstNameChange(val);
        break;
      case 'lastName':
        handleLastNameChange(val);
        break;
      default:
    }
  }

  function handleLotSelection(id) {
    if (isNotEmpty(selectedLots) && selectedLots.includes(id)) {
      setSelectedLots(selectedLots.filter(selectedId => selectedId !== id));
    } else {
      setSelectedLots([...selectedLots, id]);
    }
  };

  function checkNameLength() {
    const checkFirst = firstName.length > 0 && firstName.length <  51;
    const checkLast = lastName.length > 0 && lastName.length <  51;

    return checkFirst && checkLast && isNotEmpty(selectedLots);
  }

  function checkLotDifference() {
    const lots = vip.lots;
    const final = [];

    lots.forEach(initLot => selectedLots.forEach(selId =>  initLot.id === selId ? final.push(selId) : false));

    return (final.length !== lots.length || selectedLots.length > lots.length) && checkNameLength();
  };

  function checkNameDifference() {
    const nameDifference = firstName !== vip.firstName || lastName !== vip.lastName;

    return nameDifference && checkNameLength();
  };

  function duplicateVipCheck() {
    if (checkNameDifference()) {
      const existingDuplicates = eventsData.event[0].vips.map(item => item.fullName)
        .filter((fullName) => fullName.toLowerCase() === `${firstName} ${lastName}`.toLowerCase());

      if (existingDuplicates.length > 0) { 
        setDuplicates(existingDuplicates);
        return setDuplicatesModalVisible(true);
      }
    }

    return handleEdit();
  };

  function hideModal(name) {
    switch (name) {
      case 'duplicate':
        setDuplicatesModalVisible(false);
        break;
      case 'back':
        setBackModalVisible(false);
        break;
      default:
    }
  }

  function showModal() {
    return checkNameDifference() || checkLotDifference() ? setBackModalVisible(true) : history.push(routes.VIPS);
  };

  function isButtonEnabled() {
    return checkLotDifference() || checkNameDifference();
  };

  function isLotSelected(id) {
    return (isNotEmpty(selectedLots) && selectedLots.includes(id));
  };

  function handleEdit() {
    const variables = {
      eventIds: getItem('selectedEvents'),
      lotIds: selectedLots,
      vip: { firstName: firstName, lastName: lastName, id: vip.id }
    };

    updateVIP({
      variables
    });
  };

  const allLotsSelected = selectedLots.length === eventsData?.event[0].lots.length;

  function handleSelectAll() {
    if (allLotsSelected) {
      return setSelectedLots([]);
    }

    const lots = eventsData.event[0].lots;
    const lotIds = lots.map(lot => lot.id);

    setSelectedLots(lotIds);
  }

  return (
    <>
      {!loading && !error &&
        <div className="wrapper edit-vips">
          <section className="row">
            <div className="col-xs">
              <SubHeader label="Editing VIP Details" backAction={showModal} />
            </div>
          </section>
          <section className="row">
            <div className="names col-xs">
              <span className="title-name">Name</span>
              <div className="name-fields row">
                <TextField
                  autoComplete="off"
                  floating
                  id="first-name"
                  name="first-name"
                  label="First"
                  className="first col-xs-5 col-md-6"
                  onChange={(e) => handleNameChange(e, 'firstName')}
                  maxLength={50}
                  errorText="Input cannot exceed 50 characters"
                  defaultValue={firstName}
                />
                <TextField
                  autoComplete="off"
                  floating
                  className="last col-xs-5 col-md-6"
                  id="last-name"
                  name="last-name"
                  label="Last"
                  onChange={(e) => handleNameChange(e, 'lastName')}
                  maxLength={50}
                  errorText="Input cannot exceed 50 characters"
                  defaultValue={lastName}
                />
              </div>
            </div>
          </section>
          <section className="row ">
            <div className="col-xs lots-title">
              <span className="title-lots">Lots</span>
              <p className="subtitle-lots">Only lots within this event are shown</p>
            </div>
          </section>
          <section className="row">
            <div className="col-xs">
              <Checkbox
                inkDisabled
                checked={allLotsSelected}
                className="lot-select-all"
                id="select-all-control"
                label="Select All"
                name="list-control-checkbox"
                onChange={handleSelectAll}
              />
              <List className="list">
                {isNotEmpty(eventsData.event[0].lots) && eventsData?.event[0]?.lots.map((lot, index) => (
                  <ListItemControl
                    key={index}
                    className={`lot-li ${isLotSelected(lot.id) ? 'selected' : ''}`}
                    primaryAction={
                      <Checkbox
                        id={`list-control-${index}`}
                        name={`list-control-${index}`}
                        label={lot.name}
                        onChange={() => handleLotSelection(lot.id)}
                        checked={isLotSelected(lot.id)}
                      />
                    }
                  />
                ))}
              </List>
            </div>
          </section>
          <Footer>
            <Button
              disabled={!isButtonEnabled()}
              flat
              onClick={() => duplicateVipCheck()}
              primary
              swapTheming
            >
              update vip
            </Button>
          </Footer>
          <DialogContainer
            id="duplicates-modal"
            className="dialog"
            focusOnMount={false}
            visible={duplicatesModalVisible}
            stackedActions={false}
            title="Create duplicates?"
            onHide={hideModal}
            width="510px"
            actions={[
              <Button key="edit" swapTheming primary flat onClick={() => hideModal('duplicate')}>Edit List</Button>,
              <Button key="continue" onClick={() => handleEdit()} swapTheming secondary flat>Continue</Button>
            ]}
            modal
          >
            <p>You already have the following entries in your VIP list:</p>
            <ul className="duplicates">
              {duplicatesModalVisible && (duplicates.map((fullName, index) => <li key={index}>{fullName}</li>))}
            </ul>
          </DialogContainer>
          <DialogContainer
            id="discard-modal"
            className="dialog"
            focusOnMount={false}
            visible={backModalVisible}
            stackedActions={false}
            title="Discard changes?"
            onHide={hideModal}
            width="510px"
            actions={[
              <Button key="edit" swapTheming primary flat onClick={() => hideModal('back')}>Stay Here</Button>,
              <Button key="continue" swapTheming secondary flat onClick={() => history.push(routes.VIPS)}>Discard Changes</Button>
            ]}
            modal
          >
            <p>You'll lose all the progress you've made</p>
          </DialogContainer>
        </div>
      }
      <LoadingSpinner show={loading || updateLoading} delay={0} />
    </>
  );
};

export default EditVIP;
