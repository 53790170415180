import './Csv.scss';
import React, { useCallback, useState, useEffect } from 'react';
import { Card, FontIcon } from 'react-md';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { SubHeader } from '../../components';
import * as routes from '../../constants/routes';
import StyledDropZone from './StyledDropZone';
import { isNotEmpty, getItem } from '../../utils';
import CSVResponseDialog from '../../components/CSVResponseDialog/CSVResponseDialog.jsx';
import { postCSV } from '../../requests';

const Csv = ({ updateToast }) => {
  const history = useHistory();
  const location = useLocation();

  const [CSVdialogVisible, setCSVdialogVisible ] = useState(false);
  const [CSVErrorText, setCSVErrorText] = useState([]);
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    if (!location.state || !location.state.selectedLotsForCSV) {
      history.push(routes.LOTS);
    }
  }, [location, history]);

  function hide() {
    setCSVdialogVisible(false);
  }

  function show() {
    setCSVdialogVisible(true);
  }

  const onDrop = useCallback(acceptedFiles => {
    function setErrorDialog(error) {
      setCSVErrorText(error);
      show();
    }

    const events = getItem('selectedEvents');
    if (isNotEmpty(acceptedFiles)) {
      if (acceptedFiles[0].name.endsWith('.csv')) {
        setPosting(true);
        return postCSV(acceptedFiles[0], location.state.selectedLotsForCSV, events).then(response => {
          updateToast([{
            text: `Successfully added ${response.data.uniqueRecordCount} VIP(s) to ${events.length} event(s)`,
            action: 'dismiss'
          }]);
          history.push(routes.ROOT, { refetch: true });
        })
          .catch(error => {
            setPosting(false);
            if (error.response) {
              return setErrorDialog(error.response.data || error);
            }
            return setErrorDialog({ setError: 'Something went wrong, please try again.' });
          });
      }
    }

    return setErrorDialog({ setError: 'Only .CSV file types, please try again.' });
  }, [history, location.state.selectedLotsForCSV, updateToast]);

  return (
    <div className="wrapper">
      <div id="csv">
        <section className="row">
          <div className="col-xs">
            <SubHeader
              backAction={() => history.push(routes.LOTS)}
              label="Upload .CSV file"
              subText="You can download the template below to make sure your data is formatted correctly"
            />
          </div>
        </section>
        <section className="main-content row center-xs">
          <div className="col-xs-12 col-sm-8 col-md-5 col-lg-5">
            <Card className="card">
              <div className="dropzone-container">
                <StyledDropZone onDrop={onDrop} posting={posting} />
              </div>
            </Card>
            <a className="download-link" download="vip_template.csv" role="button" href="vip_template.csv">
              <div className="md-icon-separator">
                <FontIcon className="md-text--inherit">get_app</FontIcon>
                <span className="md-icon-text">Download Template</span>
              </div>
            </a>
          </div>
        </section>
      </div>
      <CSVResponseDialog
        onHide={hide}
        visible={CSVdialogVisible}
        data={CSVErrorText}
      />
      <LoadingSpinner show={posting} delay={0} />
    </div>
  );
};

export default Csv;
