import './VIPS.scss';
import React, { useState, useEffect } from 'react';
import { DialogContainer, Button } from 'react-md';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from '../../constants/routes';
import { LoadingSpinner } from '@parkhub/parkhub-ui';
import { Footer, SubHeader, VIPSListActionBar, EventVIPSTable } from '../../components';
import { getItem, dateTimeFormatter, FUSE_OPTIONS } from '../../utils';
import { useQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import Fuse from 'fuse.js';
import moment from 'moment';
import { isNotEmpty } from './../../utils';
import NoVips from '../../assets/Illustrations/NoVips.svg';
import _ from 'lodash';

const vipByEvent = loader('../../graphql/vipByEvent.query.graphql');
const deleteVIPsMutation = loader('../../graphql/deleteVIPs.mutation.graphql');
const storageKeyEvents = 'selectedEvents';
const vipsListLabelKeys = [
  { label: 'VIP Name', key: 'fullName', sortable: true },
  { label: 'Lot Permissions', key: 'lots', sortable: true },
  { label: 'Date Added', key: 'createdAt', sortable: true, formatter: dateTimeFormatter }
];

const VIPs = ({ updateToast }) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedVips, setSelectedVips] = useState([]);
  const [duplicateFilter, setDuplicateFilter] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [duplicateVIPs, setDuplicateVIPs] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { loading, error, data: vipData, refetch } = useQuery(vipByEvent, { variables: { eventIds: getItem('selectedEvents') } });
  const _FUSE_OPTIONS = { ...FUSE_OPTIONS, keys : [ 'fullName'] };
  const [deleteVIPs] = useMutation(deleteVIPsMutation, {
    onCompleted(data) {
      if (data?.deleteVIPs.uniqueRecordCount) {
        updateToast([{
          text: `${selectedVips.length} ${selectedVips.length === 1 ? 'VIP' : 'VIPs'} deleted`,
          action: 'dismiss'
        }]);

        refetch();
        setSelectedVips([]);
      }
      setDeleting(false);
      setDialogVisible(false);

      if (toggled) {
        handleSwitch();
      }
    },
    onError(...error) {
      console.log(error);
      setDeleting(false);
      setDialogVisible(false);

      if (toggled) {
        handleSwitch();
      }

      updateToast([{
        text: `Error deleting VIP. If the problem persists please contact administrator`,
        action: 'dismiss'
      }]);
    }
  });

  useEffect(()=> {
    if (location.state && location.state.refetch) {
      refetch();
    }
  }, [location, refetch]);

  useEffect(() => {
    if (!getItem(storageKeyEvents) || !isNotEmpty(getItem(storageKeyEvents)) || getItem(storageKeyEvents).length > 1) {
      history.push(routes.ROOT);
    }
  }, [history]);

  function show() {
    setDialogVisible(true);
  };

  function hide() {
    setDialogVisible(false);
  };

  function findDuplicates() {
    const names = {};
    const duplicates = [];

    vipData.event[0].vips.forEach(vip => {
      const fullName = vip.fullName;
      const combined = fullName.replace(/[^0-9a-z]/gi, '').toLowerCase();

      if (names.hasOwnProperty(combined)) {
        return names[combined] = [...names[combined], vip.id];
      }

      return names[combined] = [vip.id];
    });

    for (var name in names) {
      if (names[name].length > 1) {
        duplicates.push(names[name]);
      }
    }

    return duplicates.flat();
  }

  function filterVIPS() {
    const duplicateIdArray = findDuplicates();
    const duplicateVIPS = vipData.event[0].vips.filter(vip => duplicateIdArray.includes(vip.id));

    return setDuplicateVIPs(duplicateVIPS);
  }

  function increaseCount(id) {
    setSelectedVips(prevState => [...prevState, id]);
  }

  function decreaseCount(id) {
    setSelectedVips(prevState => prevState.filter(vipId => vipId !== id));
  }

  function handleSwitch() {
    setSelectedVips([]);

    filterVIPS();
    setToggled(prevState => !prevState);
    setDuplicateFilter(prevDuplicateFilter => (!prevDuplicateFilter));
  }

  function goBack() {
    history.push(routes.ROOT, { refetch: true });
  }

  function formatAndDelete() {
    setDeleting(true);
    let vipIds = selectedVips;
    vipIds = vipData.event[0].vips.filter(vip => vipIds.includes(vip.id)).map(vip => vip.id);

    deleteVIPs({
      variables: {
        ids: vipIds,
        eventId:  vipData.event[0].id
      }
    });
  }

  function createFilteredList() {
    const searchList = vipData?.event[0].vips;
    const fuse = new Fuse(searchList, _FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const formattedResults = results.map(result => result.item);

    return searchTerm === '' ? vipData?.event[0].vips : formattedResults;
  }

  function getSelectedVip() {
    return vipData.event[0].vips.filter(vip => vip.id === selectedVips[0])[0];
  }

  const vipFilteredData = vipData && createFilteredList();
  
  return (
    <>
      {!loading && !error && vipData?.event[0]?.vips &&
        <div className="wrapper">
          <SubHeader
            backAction={() => goBack()}
            label={vipData.event[0].name}
            subText={`${vipData.event[0].landmark.name} - ${moment(vipData.event[0].from).format('MM/DD/YY h:mm a')}`}
          />
          {!loading && !error && vipData.event[0].hasOwnProperty('vips') &&
            <div>
              <VIPSListActionBar
                handleSwitch={handleSwitch}
                vips={duplicateFilter ? duplicateVIPs : vipData.event[0].vips}
                selected={selectedVips}
                toggled={toggled}
                setSelectedVips={setSelectedVips}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              {
                isNotEmpty(vipData.event[0].vips) && (!duplicateFilter || (duplicateFilter && findDuplicates().length > 0)) ?
                  <EventVIPSTable
                    labelKeys={vipsListLabelKeys}
                    storageKey="selectedVips"
                    increaseCount={increaseCount}
                    decreaseCount={decreaseCount}
                    selected={selectedVips}
                    duplicates={findDuplicates()}
                    data={duplicateFilter ? duplicateVIPs : _.orderBy(vipFilteredData, [x => x.fullName && x.fullName.toString().toLowerCase()], 'desc')}
                    sortable
                  /> :
                  <div className="row no-show">
                    <img
                      src={NoVips}
                      alt="tooltip"
                    />
                    <p>
                      {duplicateFilter ? 'No VIPs to show.' : 'There are no VIPs associated to this event yet.'}
                    </p>
                  </div>
              }
            </div>
          }
        </div>
      }
      <Footer>
        <>
          <Button
            secondary
            swapTheming
            flat
            disabled={selectedVips.length === 0}
            onClick={() => show()}
          >
            delete
          </Button>
          <Button
            primary
            swapTheming
            onClick={() => history.push(routes.EDIT_VIP, { vip: getSelectedVip() })}
            disabled={selectedVips.length !== 1}
            flat
          >
            edit
          </Button>
        </>
      </Footer>
      <DialogContainer
        id="csv-upload-dialog"
        className="dialog"
        visible={dialogVisible}
        onHide={hide}
        stackedActions={false}
        width="510px"
        modal
        actions={[
          <Button key="edit" swapTheming primary flat onClick={hide}>back to list</Button>,
          <Button
            key="continue"
            swapTheming
            secondary
            flat
            onClick={() => formatAndDelete()}
          >
            {`Delete ${selectedVips.length} ${selectedVips.length === 1 ? 'VIP' : 'VIPs'}?`}
          </Button>
        ]}
        title={`Delete ${selectedVips.length} ${selectedVips.length === 1 ? 'VIP' : 'VIPs'}`}
      >
        <p>
          This action cannot be undone.
        </p>
      </DialogContainer>
      <LoadingSpinner show={loading || deleting} delay={0} />
    </>
  );
};

export default VIPs;
