import './Footer.scss';
import React from 'react';

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="row end-xs middle-xs footer-actions">
          <div className="col-xs-6">
            {props.children}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
